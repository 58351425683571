import styled from "@emotion/styled";
import { Link } from "gatsby";

const ReadLink = styled(Link)`
  display: inline-block;
  font-size: 0.875rem;
  background-color: transparent;
  color: #479AD1;
  padding: 0px 0.125rem;
  transition: all 150ms linear 0s;

  &:hover {
    background-color: #479AD1;
    color: rgb(255, 255, 255);
    border-radius: 0.25rem;
    outline: 0px;
    text-decoration: none;
  }
`;

export const ReadLinkExternal = styled.a`
  display: inline-block;
  font-size: 1rem;
  background-color: transparent;
  color: #479AD1;
  padding: 0px 0.125rem;
  transition: all 150ms linear 0s;
  margin-top: 0;

  &:hover {
    background-color: #479AD1;
    color: rgb(255, 255, 255);
    border-radius: 0.25rem;
    outline: 0px;
    text-decoration: none;
  }
`;

export default ReadLink;
