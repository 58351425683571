import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ links }) => {
  const linkTags = links.map(({ rel, href }) => (
    <link key={href} rel={rel} href={href} />
  ));
  return <Helmet>{linkTags}</Helmet>;
};

export default Meta;
